import { dehydrate, QueryClient } from "@tanstack/react-query"
import { GetStaticPropsContext, NextPage } from "next"

import { useRouter } from "next/router"
import { fetchNewPaymentMethods } from "pages/apartments/services/queryFunctions"
import { fetchBanners } from "pages/app/services/queryFunctions"
import {
  BannerImageType,
  BannerPosition,
  IBanner,
  IBannerImage,
} from "pages/app/utils/models/banner"
import $api from "services/RequestService"
import { UZ } from "utils/constants/locales"
import NextHead from "../src/common/next-head/NextHead"
import { queryKeys as appQueryKeys } from "../src/pages/app/utils/constants/queryKeys"
import Main from "../src/pages/main/Index"
import {
  fetchApartmentCounts,
  fetchHotFilters,
  fetchTopBuilders,
  fetchTopComplexes,
  fetchTopMagazines,
} from "../src/pages/main/services/queryFunctions"
import { queryKeys } from "../src/pages/main/utils/constants/queryKeys"
import { Pagination } from "utils/models/pagination"
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel"

const Index: NextPage = (props) => {
  const { locale } = useRouter()

  const title =
    locale === UZ
      ? "Toshkentdan kvartira sotib oling | Uysot.uz\n"
      : "Купить квартиру в новостройке в Ташкенте - Uysot.uz"
  const description =
    locale === UZ
      ? "Toshkentda yangi kvartira qidiryapsizmi? Uysot.uz saytida siz turar-joy majmualarining katta tanlovini topasiz. Ipoteka, muddatli to’lov, hamyonbop narxlar, boshlang’ich to’lovsiz uylar"
      : "Ищете новую квартиру в Ташкенте? На Uysot.uz вы найдете большой выбор новостроек от ведущих застройщиков. Ипотека, рассрочка, выгодные цены."
  const keyWords =
    locale === UZ
      ? "Toshkent kvartira, Toshkentda kvartira sotib olish, yangi uylar Toshkent, ko'chmas mulk Toshkent, ipoteka Toshkent, bo'lib to'lash Toshkent, Toshkent shahridagi uy-joylar, Toshkent markazidagi yangi uylar, Toshkent chekkasida yangi uylar, Toshkent novostroyka, Toshkent uylar narxi"
      : "купить квартиру в Ташкенте, новостройки Ташкент, недвижимость Ташкент, ипотека Ташкент, рассрочка Ташкент, квартиры в новостройках, жилье в Ташкенте, новостройки в центре Ташкента, новостройки на окраине Ташкента"

  return (
    <>
      <NextHead
        title={title}
        description={description}
        keywords={keyWords}
        ogDescription={description}
        ogTitle={title}
      />
      <Main
        banner={
          (
            props as {
              banner: Partial<
                Record<
                  BannerPosition,
                  IBanner<Record<BannerImageType, IBannerImage>>
                >
              >
              topComplexes: Pagination<NewResidentalComplexModel>
            }
          )?.banner
        }
        topComplexes={
          (
            props as {
              banner: Partial<
                Record<
                  BannerPosition,
                  IBanner<Record<BannerImageType, IBannerImage>>
                >
              >
              topComplexes: Pagination<NewResidentalComplexModel>
            }
          )?.topComplexes
        }
      />
    </>
  )
}

export default Index

export async function getStaticProps(context: GetStaticPropsContext) {
  const queryClient = new QueryClient()

  const banner = await fetchBanners({ page_type: "main", lang: context.locale })

  const hotQueryKeys = [appQueryKeys.HOT_FILTERS]

  const { url, fetch } = fetchTopComplexes()
  const topComplexes = await fetch()

  await Promise.allSettled([
    queryClient.prefetchQuery(
      [queryKeys.APARTMENT_COUNTS],
      fetchApartmentCounts
    ),
    queryClient.prefetchQuery([queryKeys.TOP_BUILDERS], fetchTopBuilders),
    queryClient.prefetchQuery(
      [appQueryKeys.BANNER({ page_type: "main", lang: context.locale })],
      () => banner
    ),
    queryClient.prefetchQuery(
      [queryKeys.NEW_TOP_MAGAZINES, context.locale],
      () => fetchTopMagazines(context.locale)
    ),
    queryClient.prefetchQuery(
      [appQueryKeys.PAYMENT_METHODS_SELECT],
      fetchNewPaymentMethods
    ),
    queryClient.prefetchQuery(hotQueryKeys, () => fetchHotFilters()),
    queryClient.prefetchQuery([queryKeys.MAIN_REGIONS], async () => {
      const res = await $api.get(`/city/select`)
      return res.data.data
    }),
    queryClient.prefetchQuery([queryKeys.TOP_COMPLEXES, url], fetch),
    queryClient.prefetchQuery([appQueryKeys.METROS], async () => {
      const res = await $api.get(`/metro-line/select`)
      return res.data.data
    }),
  ])

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      messages: (await import(`../messages/main/${context.locale}.json`))
        .default,
      banner,
      topComplexes,
    },
    revalidate: 86400,
  }
}
