import { useLocale, useTranslations } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import React from "react"

import { useAppSelector } from "../../../../hooks/reduxHooks"
import {
  imageLayouts,
  imageObjectFits,
} from "../../../../utils/constants/imageAttributes"
import {
  ROOMS_COUNT,
  DEADLINE_YEARS,
} from "../../../../utils/constants/queryParamsKeys"
import { rootPaths } from "../../../../utils/constants/rootPaths"
import calculatorImg from "../../assets/images/useful-links-calculator.webp"
import complexesImg from "../../assets/images/useful-links-complexes.webp"
import { useGetApartmentCounts } from "../../services/queries"

import styles from "./MainUsefulLinks.module.scss"
import dayjs from "dayjs"

const MainUsefulLinks: React.FC = () => {
  const t = useTranslations("body.usefulLinks")
  const locale = useLocale()
  const { data: apartmentCounts } = useGetApartmentCounts()
  const { location: currentLocation } = useAppSelector(
    (state) => state.favouritesReducer
  )
  const deadlineReadyHouse = dayjs()
    .add(-1, "year")
    .endOf("year")
    .format("YYYY")

  const deadlineEndYear = dayjs().endOf("year").format("YYYY")

  const deadlineNextYear = dayjs().add(1, "year").endOf("year").format("YYYY")

  const deadlineNextTwoYear = dayjs()
    .add(2, "year")
    .endOf("year")
    .format("YYYY")

  // location
  const location =
    currentLocation?.regionValue && currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url || "gorod-tashkent"

  const deadlineFilters = [
    {
      id: 1,
      deadlineYears: deadlineReadyHouse,
      item: t("Готовые ЖК"),
    },
    {
      id: 2,
      deadlineYears: deadlineEndYear,
      item: t("Сдача до конца года"),
    },
    {
      id: 3,
      deadlineYears: deadlineNextYear,
      item:
        locale === "ru"
          ? t("Сдача до 2023") + dayjs().add(1, "year").format("YYYY")
          : dayjs().add(1, "year").format("YYYY") + t("Сдача до 2023"),
    },
    {
      id: 4,
      deadlineYears: deadlineNextTwoYear,
      item:
        locale === "ru"
          ? t("Сдача до 2024") + dayjs().add(2, "year").format("YYYY")
          : dayjs().add(2, "year").format("YYYY") + t("Сдача до 2024"),
    },
  ]

  return (
    <section className={styles.container}>
      <div className="wrapper">
        <h2 className={styles.container_title}>{t("Полезные ссылки")}</h2>
        <div className={`${styles.container_body} flex-wrap`}>
          <div className={styles.apartments}>
            <h3 className={styles.title}>{t("Купить квартиру")}</h3>
            <div className={styles.body}>
              {apartmentCounts?.map((item, index) => (
                <Link
                  key={index}
                  href={{
                    pathname: `/${location}${rootPaths.APARTMENTS}`,
                    query: {
                      [ROOMS_COUNT]: item.rooms_count,
                    },
                  }}
                  legacyBehavior
                >
                  <a>
                    <p>
                      {item?.rooms_count}-{t("комнатная")}
                    </p>
                    <span>{item?.apartments_count}</span>
                  </a>
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.complexes}>
            <h3 className={styles.title}>{t("Новостройка")}</h3>
            <div className={styles.body}>
              {deadlineFilters?.map((item) => (
                <Link
                  href={{
                    pathname: `/${location}${rootPaths.RESIDENTIAL_COMPLEXES}`,
                    query: { [DEADLINE_YEARS]: item?.deadlineYears },
                  }}
                  legacyBehavior
                  key={item?.id}
                >
                  <a>{item?.item}</a>
                </Link>
              ))}
            </div>
            <div className={styles.image}>
              <Image
                src={complexesImg}
                alt="usefull-link"
                layout={imageLayouts.RESPONSIVE}
                objectFit={imageObjectFits.CONTAIN}
                // width="100%"
                // height="100%"
              />
            </div>
          </div>
          <div className={styles.calculator}>
            <div className={styles.left}>
              <h3 className={styles.title}>{t("Калькулятор ипотеки")}</h3>
              <p>
                {t("Рассчитайте платежи")} <br /> {t("и отправьте заявку")}
              </p>
              <Link href={rootPaths.MORTGAGE_CALCULATOR} legacyBehavior>
                <a>{t("Рассчитать ипотеку")}</a>
              </Link>
            </div>
            <div className={styles.right}>
              <Image
                src={calculatorImg}
                alt="calculator-img"
                layout={imageLayouts.RESPONSIVE}
                objectFit={imageObjectFits.CONTAIN}
                // width="100%"
                // height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainUsefulLinks
