import $api from "../../../services/RequestService"
import { endpoints } from "../utils/constants/apis"

// top complexes
export const fetchTopComplexes = () => {
  const url = `/complex/top`

  return {
    fetch: async () => {
      const res = await $api.get(url)
      return res.data?.data ? res.data?.data : res.data
    },
    url,
  }
}

// apartment count
export const fetchApartmentCounts = async () => {
  const res = await $api.get(endpoints.APARTMENT_COUNT)
  return res.data.data
}

// top builders
export const fetchTopBuilders = async () => {
  const res = await $api.get(endpoints.TOP_BUILDERS)
  return res.data.data
}

// top magazines
export const fetchTopMagazines = async (
  lang?: string,
  article_ids?: number[]
) => {
  let url = `article/top?lang=${lang || "ru"}&top=true`

  if (article_ids && article_ids?.length > 0) {
    article_ids.forEach((article_id) => {
      url += `&article_ids[]=${article_id}`
    })
  }

  const res = await $api.get(url)
  return res.data.data
}

// hot filters

export const fetchHotFilters = async (
  city_id?: number,
  district_id?: number
) => {
  const url =
    city_id && district_id
      ? `${endpoints.HOT_FILTERS}?city_id=${city_id}&district_id=${district_id}`
      : city_id
      ? `${endpoints.HOT_FILTERS}?city_id=${city_id}`
      : `${endpoints.HOT_FILTERS}`

  const res = await $api.get(url)

  return res.data.data
}
