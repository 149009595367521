import { layoutLang } from "utils/language/layout"

export const filterPriceSeparator = (price: number, locale?: any) => {
  if (price < 1000) {
    return price
  }
  if (price < 1000000) {
    return (price / 1000)?.toString()?.includes(".")
      ? (price / 1000).toFixed(1) + " " + layoutLang[String(locale)]["ming"]
      : (price / 1000).toFixed(0) + " " + layoutLang[String(locale)]["ming"]
  }
  if (price < 1000000000) {
    return (price / 1000000)?.toString()?.includes(".")
      ? (price / 1000000).toFixed(1) + " " + layoutLang[String(locale)]["mln"]
      : (price / 1000000).toFixed(0) + " " + layoutLang[String(locale)]["mln"]
  }
  if (price < 100000000000) {
    return (price / 1000000000)?.toString()?.includes(".")
      ? (price / 1000000000).toFixed(1) +
          " " +
          layoutLang[String(locale)]["mlrd"]
      : (price / 1000000000).toFixed(0) +
          " " +
          layoutLang[String(locale)]["mlrd"]
  }
  return (price / 1000000000000)?.toString()?.includes(".")
    ? (price / 1000000000000).toFixed(1) +
        " " +
        layoutLang[String(locale)]["trln"]
    : (price / 1000000000000).toFixed(0) +
        " " +
        layoutLang[String(locale)]["trln"]
}
