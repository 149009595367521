import React from "react"

import MainMagazines from "./components/magazines/MainMagazines"
import MainNewComplexes from "./components/new-complexes/NewComplexes"
import MainTopBuilders from "./components/top-builders/MainTopBuilders"
import MainTop from "./components/top/MainTop"
import MainUsefulLinks from "./components/useful-links/MainUsefulLinks"
import SeoText from "./components/seo-text/SeoText"
import HotOffers from "./components/hot-offers/HotOffers"

import styles from "./Index.module.scss"
import { cx } from "utils/cx"
import {
  BannerImageType,
  BannerPosition,
  IBanner,
  IBannerImage,
} from "pages/app/utils/models/banner"
import { Pagination } from "utils/models/pagination"
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel"

type Props = {
  banner: Partial<
    Record<BannerPosition, IBanner<Record<BannerImageType, IBannerImage>>>
  >
  topComplexes: Pagination<NewResidentalComplexModel>
}

const Index: React.FC<Props> = ({ banner, topComplexes }) => {
  return (
    <div className="relative">
      <MainTop banner={banner} />
      <div className={cx("flex justify-center gap-6  w-full", styles.box)}>
        <div className="desctop_hot_offers">
          <HotOffers />
        </div>
        <div className="mb-10">
          <MainNewComplexes banner={banner} topComplexes={topComplexes} />
          <MainUsefulLinks />
          <MainTopBuilders />
          <MainMagazines />
          <SeoText />
        </div>
      </div>
    </div>
  )
}

export default Index
